import { getCurrencySymbol } from "../../lib/helper";

export const overviewData = [
    {
        label: "Total Sales",
        numericValue: "$ 500",
        graphTitle: "Total Sales",
        graphData: [],
    },
];

let currency = window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "USD"
const currency_symbol = getCurrencySymbol(currency)
export const defaultSelectedCurrency = {
    symbol: currency_symbol,
    currency: currency,
};
