import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./dateRangePicker.scss";


const { RangePicker } = DatePicker;




class DateRangePicker extends React.Component {

  render() {
    return (
      <div style={{width:"17rem"}}>
        <RangePicker onChange={this.props.onChange}
          ranges={this.props.ranges}
          value={[this.props.value[0] ? moment(this.props.value[0]) : null, this.props.value[1] ? moment(this.props.value[1]) : null]}
          onChange={this.props.onChange}
          disabledDate={current => {
            return current && current > moment().add(0, "days");
          }}
        />
      </div>
    );
  }
}
export default DateRangePicker;
