import { get } from "../lib/request";
import moment from "moment";

const unixTimeStamp = (timestamp) => {
    return moment(timestamp).unix()
};

export const getCouponDataApi = (startDate, endDate, selectedCurrency, selectedStoreId, selectedGroupBy) => {
    let url = `/promo/analysis/count?start_timestamp=${unixTimeStamp(startDate)}&end_timestamp=${unixTimeStamp(endDate)}&store_id=${selectedStoreId}&timezone=Asia/Calcutta&group_by=${selectedGroupBy}&currency=${selectedCurrency.currency}`
    return get(url);
};