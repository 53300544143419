//library import
import React from "react";
import moment from "moment";

import Masonry from "react-masonry-css";
//custom import
import ReactHighcharts from "../../../components/reactHighCharts/reactHighCharts";
import CheckBoxW from "../../../components/checkBox/checkBox";
import ReactSelect from "../../../components/reactSelect/reactSelect";
import ReactSelectGrouped from "../../../components/reactSelect/reactSelectGrouped";

import { dateFilter } from "../../../fixtures/dateFilter/dateFIlter";
import { overviewData } from "../../../fixtures/dummyApi/dummyApi";
import DateRangePicker from "../../../components/dateRangePicker/dateRangePicker";
import Link from "../../../components/Link/Link";
//scss
import "./Report.scss";

//
import PaginationJs from "../../../components/paginationJs/paginationJs";
import ReactTable from "../../../components/reactTable/reactTable";
import { fetchReviewLogs } from "../../../services/reviewLogs";
import { reviewLogsColumns } from "../../../fixtures/tableColumns/reviewLogs";
import Loader from "../../../components/loader/Loader";

class Report extends React.Component {
  state = {
    date: {
      //start date and end date
      startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    },
    loader: true, //loader state
    selected: { value: "Hour", label: "Hour" },
    selectData: [
      { value: "Hour", label: "Hour" },
      { value: "None", label: "None" },
    ],

    logsData: [], //table data
    columns: [...reviewLogsColumns], //table columns

    skip: 0, //starting point
    limit: 20, //ending point
    page: 1, //pagination page
    dataToShow: 20, //20, 40, 60 or 80 data to show
    totalData: Number, // total no of data

    isIsNotData: [
      { value: "Is", label: "Is" },
      { value: "IsNot", label: "Is not" },
    ],
    isIsNotselected: [{ value: "Is", label: "Is" }],

    searchInput: "",
    searchOption: [{ value: "", label: "N/A" }],

    filterArray: [0],
    filterWindow: false,
  };

  selectHandler = (option) => {
    this.setState({ selected: option });
  };

  componentDidMount = () => {
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);
  };

  handleRange = (...arges) => {
    let startDate =
      arges[0].length != 0 ? arges[0][0].format("YYYY-MM-DD HH:mm:ss") : null;
    let endDate =
      arges[0].length != 0 ? arges[0][1].format("YYYY-MM-DD HH:mm:ss") : null;

    let stateObject = { ...this.state };
    let date = { ...stateObject.date };
    date.startDate = startDate;
    date.endDate = endDate;
    stateObject.date = date;
    stateObject.loader = true;
    this.setState(stateObject, () => {});
    // this.getSubCategoty(startDate, endDate);

    // if (startDate === null || endDate === null)
    //   this.setState({ date: [startDate, endDate], compare: false });
    // else
    //   this.setState({ date: [startDate, endDate], compare: true });
  };

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    let newPage;

    if (newVal === 100) {
      newPage = Math.trunc(
        stateCopy.page % 2 == 0 ? stateCopy.page / 2 : stateCopy.page / 2 + 1
      );
    } else {
      newPage = stateCopy.page * 2 - 1;
    }

    stateCopy.page = newPage;
    stateCopy.skip = stateCopy.page * newVal - newVal;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  //get new data
  componentDidUpdate = (prevProps, prevState) => {
    //old params and new params are diff than get new data
    if (
      JSON.stringify(this.state.date) !== JSON.stringify(prevState.date) ||
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow
      //  || (JSON.stringify(this.state.selectedCity) !== JSON.stringify(prevState.selectedCity))
    ) {
      this.getData(
        this.state.date.startDate,
        this.state.date.endDate,
        this.state.skip,
        this.state.limit
        //  this.state.selectedCity.value
      );
    }

    //old params and new params are same ...close loader
    else if (this.state.loader === true) this.setState({ loader: false });
  };

  //get initial data on didmount
  componentDidMount() {
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      this.state.skip,
      this.state.limit
      // this.state.selectedCity.value
    );
  }

  //method to fetch data
  getData = (startDate, endDate, skip, limit, city) => {
    fetchReviewLogs(
      moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      skip,
      limit
      //  city
    )
      .then((data) => {
        // let cities = [{ value: "", label: "All Cities" }];

        // data.data.cityData.map((item) => {
        //   cities.push({ value: item, label: item })
        // })
        this.setState({
          logsData: data.data.categoryData,
          totalData: data.data.total_count,
          // cityData: cities,
          loader: false,
        });
      })
      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        this.setState({ loader: false });
      });
    // .catch(e => {
    //   // if (e.response.status == 404) {
    //   //   this.setState({
    //   //     logsData: e.response.data.data
    //   //   });
    //   // }
    // });
  };

  // onPageChange = arges => {
  // };

  addFilter = () => {
    let filterArray = [...this.state.filterArray];
    filterArray.push(Math.random());

    let isIsNotselected = [...this.state.isIsNotselected];
    isIsNotselected.push({ value: "Is", label: "Is" });

    this.setState({ filterArray, isIsNotselected }, () => {});
  };

  removeFilter = (index) => {
    let filterArray = [...this.state.filterArray];
    filterArray.splice(index, 1);

    let isIsNotselected = [...this.state.isIsNotselected];
    isIsNotselected.splice(index, 1);

    this.setState({ filterArray, isIsNotselected }, () => {});
  };

  changeHandler = (index, option) => {
    let isIsNotselected = [...this.state.isIsNotselected];
    isIsNotselected.splice(index, 1, option);
    this.setState({ isIsNotselected });
  };

  searchHandler = (value) => {};

  render() {
    var config = {
      chart: {
        type: "column",
      },
      title: {
        text: null,
        // 'Monthly Average Rainfall'
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Tokyo",
          data: [
            49.9,
            71.5,
            106.4,
            129.2,
            144.0,
            176.0,
            135.6,
            148.5,
            216.4,
            194.1,
            95.6,
            54.4,
          ],
        },
        // , {
        //   name: 'New York',
        //   data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

        // }, {
        //   name: 'London',
        //   data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

        // }, {
        //   name: 'Berlin',
        //   data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]

        // }
      ],
    };

    return (
      <div className="reportWrapper ">
        <div className="head">
          <div className="text-grey">
            <i class="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">Sales Over Time</div>
          <div className="d-flex text-grey mt-2 mb-4">
            <div className="mr-3">
              <i class="fas fa-print mr-1"></i>Print
            </div>
            <div className="mr-3">
              <i class="fas fa-download mr-1"></i>Export
            </div>
            <div className="mr-3">
              <i class="far fa-copy mr-1"></i>Save as
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2">Date Range</span>
            <div className="mt-2 rangePickr mb-2">
              <DateRangePicker
                // onChange={this.onDateChange}
                ranges={{ ...dateFilter }}
                value={[this.state.date.startDate, this.state.date.endDate]}
                onChange={this.handleRange}
              />
            </div>

            <span className="mr-2 ml-5">Group By</span>
            <div className="selectBody">
              <ReactSelect
                city={this.state.selectData}
                change={this.selectHandler}
                selected={this.state.selected}
              />
            </div>
          </div>
        </div>
        <div className="overviewBody">
          {this.state.selected.label !== "None" &&
            overviewData.map((item, index) => (
              <div className="" key={index}>
                <div className="card">
                  <div className="m-3 mt-4 graphTitle">
                    <span>{item.graphTitle}</span>
                  </div>
                  <div className="chart">
                    <ReactHighcharts config={config} />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="table productClickLogs">
          {
            //conditional rendering of loader
            this.state.loader && <Loader />
          }

          {/* table wrapper */}
          <div className="mt-4 tableWrapper">
            <div className="d-flex justify-content-between table-head">
              <div
                className="px-3 py-2 rounded filter-button"
                onClick={() => this.setState({ filterWindow: true })}
              >
                Manage Filters
              </div>
              <div className="px-3 py-2 rounded filter-button">
                Edit Columns<i class="fas fa-caret-down ml-2"></i>
              </div>
            </div>

            {this.state.filterWindow && (
              <div className="manage-filters-window">
                <div className="manage-filters-box">
                  <div className="d-flex justify-content-between py-4 px-3 border-bottom manage-head">
                    <div>Manage Filters</div>
                    <div onClick={() => this.setState({ filterWindow: false })}>
                      <i class="fas fa-times cursor-pointer"></i>
                    </div>
                  </div>
                  <div className="overflow">
                    <div className="p-3 filter-options">
                      {this.state.filterArray.length > 0 ? (
                        <div>
                          {this.state.filterArray.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="d-flex">
                                  <div className="w-100">
                                    <ReactSelectGrouped />
                                  </div>
                                  <div className="ml-2">
                                    <ReactSelect
                                      city={this.state.isIsNotData}
                                      isSearchable={false}
                                      selected={
                                        this.state.isIsNotselected[index]
                                      }
                                      change={this.changeHandler.bind(
                                        this,
                                        index
                                      )}
                                    />
                                  </div>
                                  <div
                                    className="ml-2 delete cursor-pointer"
                                    onClick={() => this.removeFilter(index)}
                                  >
                                    <i class="fas fa-trash-alt "></i>
                                  </div>
                                </div>
                                <div className="mt-2 search pb-3 border-bottom mb-3">
                                  <i class="fas fa-search"></i>
                                  <ReactSelect
                                    city={this.state.searchOption}
                                    // isSearchable={false}
                                    // selected={this.state.isIsNotselected}
                                    change={this.searchHandler.bind(
                                      this,
                                      index
                                    )}
                                    placeholder="Search"
                                    isMulti={true}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="no-filter-text">
                          You currently have no filters set.
                        </div>
                      )}

                      <div className="mt-4">
                        <span
                          className="add-filter cursor-pointer"
                          onClick={this.addFilter}
                        >
                          Add Filter
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 p-3 border-top d-flex justify-content-end">
                      <div></div>
                      <div className="add-filter cursor-pointer">Cancel</div>
                      <div className="apply-filter ml-2 cursor-pointer">
                        Apply Filters
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <ReactTable
              // onPageChange={this.onPageChange.bind(this)}
              lable="Product Click Logs"
              columns={this.state.columns}
              data={this.state.logsData}
              noDataText="No review logs"
              handleRange={this.handleRange.bind(this)}
            />
          </div>

          {/* paginationWrapper */}
          <div className="paginationWrapper">
            <PaginationJs
              changeActivePage={this.changeActivePage}
              activePage={this.state.page}
              totalData={this.state.totalData}
              dataToShowHandler={this.dataToShowHandler}
              dataToShow={this.state.dataToShow}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
