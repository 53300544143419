// Dependency Imports
import React from "react";
import PropTypes from "prop-types";

// Custom Imports
import "../../static/scss/App.scss";
import SidePanel from "../../components/sidePanel/sidePanel";
//icons
import HomeIcon from "@material-ui/icons/Home";
import PollIcon from "@material-ui/icons/Poll";

//helper function
import { goToLogin } from "../../lib/helper";
import { Route, withRouter, BrowserRouter as Router } from "react-router-dom";

//nested routes

// Promo Codes Routes
import PromoCodeDashboard from "./PromoCodeDashboard";
import PromoCodeUsageAnalysis from "./PromoCodeUsageAnalysis";
import PromoCodeUsageDetailedAnalysis from "./PromoCodeUsageAnalysis/promoAnalysisDetailedData";
import TopNPromo from "./TopNPromo";
import CouponAnalysis from "./CouponAnalysis";


class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [
      {
        name: "Promo Code Dashboard",
        url: `${this.props.match.url}/overview`,
        icon: HomeIcon,
        component: PromoCodeDashboard,
      },
      {
        name: "Promo Usage Analysis",
        url: `${this.props.match.url}/promo-usage-analysis`,
        icon: PollIcon,
        component: PromoCodeUsageAnalysis,
      },
      {
        name: "Promo Usage Detailed Information",
        url: `${this.props.match.url}/promo-usage-detailed-analysis/:serviceType/:storeId/:id`,
        icon: PollIcon,
        component: PromoCodeUsageDetailedAnalysis,
      },
      {
        name: "Coupon Analysis",
        url: `${this.props.match.url}/promo-analysis`,
        icon: PollIcon,
        component: CouponAnalysis,
      },
      {
        name: "Top N Promo",
        url: `${this.props.match.url}/top-n-promo`,
        icon: PollIcon,
        component: TopNPromo,
      },
    ],
  };

  // static getDerivedStateFromProps(props, prevState) {
  //   goToLogin(props.history);
  // }

  render() {
    if (this.props.location.pathname == "/dashboard") {
      this.props.history.push("/dashboard/overview");
    }
    return (
      <div className="dash-board">
        {/* <Header title="shoppd - Dashboard"></Header> */}

        <div className="dashbord-body">
          <SidePanel
            data={this.state.sidePanel}
            url={`${this.props.match.url}/overview`}
          ></SidePanel>

          <div className="side-panel-body">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
        {/* <CartLogs cartLogs={this.state.cartLogs} />
        <ProductView ProductRecentView={this.state.ProductRecentView} />
        <FavProduct FavProduct={this.state.FavProduct} /> */}
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

export default withRouter(LandingIndex);
