import React, { Component } from "react";
import { dateFilter } from "../../../../fixtures/dateFilter/dateFIlter";
import DateRangePicker from "../../../../components/dateRangePicker/dateRangePicker";
import moment from "moment";

import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/";
import Menu from "@material-ui/core/Menu";
import Input from "../../../../components/InputBox/Input";
import Button from "@material-ui/core/Button";
import Currency from "../../../../components/module/currency";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: 12.8,
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      date: [],
      selectedGroupByPeriod: { value: "0", label: "Hour" },
      selectGroupByPeriodList: [
        { value: 0, label: "Hour" },
        { value: 1, label: "Day" },
        { value: 2, label: "Week" },
        { value: 3, label: "Month" },
        { value: 4, label: "Quarter" },
        { value: 5, label: "Year" },
        { value: 6, label: "Hour Of Day" },
        { value: 7, label: "Day Of Week" },
      ],
      numberOfDays: 0,
      selectedCurrency: props.currency,
      groupBy: props.groupBy,
      groupByName: props.groupByName,

    };
  }

  setGroupBy = (numberOfDays) => {
    if (numberOfDays === 0) {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[0])
      );
      this.setState({ groupBy: 0 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[0] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else if (numberOfDays > 0 && numberOfDays <= 7) {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[1])
      );
      this.setState({ groupBy: 1 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[1] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else if (numberOfDays > 7 && numberOfDays <= 90) {
      if (numberOfDays > 30 && numberOfDays <= 90) {
        localStorage.setItem(
          "selectedGroupBy",
          JSON.stringify(this.state.selectGroupByPeriodList[2])
        );
      }
      if (numberOfDays > 7 && numberOfDays <= 30) {
        localStorage.setItem(
          "selectedGroupBy",
          JSON.stringify(this.state.selectGroupByPeriodList[1])
        );
      }
      this.setState({ groupBy: 1 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[1] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[3])
      );
      this.setState({ groupBy: 3 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[3] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    }
  };

  selectGroupByPeriodHandler = (...arges) => {
    if (arges[0]) {
      let startDate =
        arges[0].length !== 0
          ? arges[0][0].format("YYYY-MM-DD HH:mm:ss")
          : null;
      let endDate =
        arges[0].length !== 0
          ? arges[0][1].format("YYYY-MM-DD HH:mm:ss")
          : null;
      let startDatefordiff = moment(startDate).startOf("day");
      let endDatefordiff =
        moment().diff(endDate, "h") === 0 || moment().diff(endDate, "h") === "0"
          ? moment()
          : moment(endDate).endOf("day");
      let numberOfDays = endDatefordiff.diff(startDatefordiff, "days");
      endDatefordiff = moment(endDatefordiff).format("YYYY-MM-DD HH:mm:ss");
      const { selectedGroupByPeriod } = this.state;
      localStorage.setItem("startDate", String(startDate));
      localStorage.setItem("endDate", String(endDatefordiff));
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(selectedGroupByPeriod)
      );
      this.setState(
        {
          startDate: startDate,
          endDate: endDatefordiff,
        },
        () =>
          this.props.headerStateHandler("date", {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          })
      );
      if (numberOfDays !== this.state.numberOfDays) {
        this.setState({ numberOfDays: numberOfDays });
        return this.setGroupBy(numberOfDays);
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2 globalFontSize">Date Range: </span>
        <div className="mt-2 mb-2">
          <DateRangePicker
            ranges={{ ...dateFilter }}
            value={[this.props.startDate, this.props.endDate]}
            onChange={this.selectGroupByPeriodHandler}
          />
        </div>

        <Currency
          selectedCurrency={this.props.selectedCurrency}
          currencyModuleAnchor={this.props.currencyModuleAnchor}
          selectCurrency={this.props.selectCurrency}
          filteredCountries={this.props.filteredCountries}
          setCurrencyModuleAnchor={this.props.setCurrencyModuleAnchor}
        />
      </div>
    );
  }
}

export default withStyles(styles)(index);
